.recodeReviewModal {

  .modal-body {

    .pageTitle {
      font-size: large;
      color: #fff;
      margin: 20px 0;
      padding: 8px;
      border-radius: 5px;
      background: #00A0E9;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      background: #F39800 !important;
    }

    .inputSkill {
      height: 40px;
      width: 95%;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 2px;
      padding-left: 10px;
    }

    .uploadstep {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;

      .uploadbox {
        position: relative;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        color: #fff;
      }
    }

    .submit-opinion {
      display: flex;
      justify-content: right;

      .btn-left {
        background: #75c711;
        color: #fff;
        font-weight: bold;
        padding: 8px 36px;
        border-radius: 8px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: 0.5s;
        border: 0;
        margin-top: 30px;
      }
      :disabled{
        opacity: 0.6;
      }
      .btn-right {
        margin-left: 30px;
        background: #2aabe7;
      }

    }
    .qrCodeBtnBox{
      :disabled{
        opacity: 0.6;
      }
      .submit-tow-code{
        background: rgb(0, 160, 233);
        color: #fff;
        font-weight: bold;
        width: 125px;
        height: 38px;
        border-radius: 8px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: 0.5s;
        border: 0;
        margin-top: 30px;
        margin-left: calc(100% - 130px);
      }
    }
    .upload-two-code {
      color: black;
      background-color: white;
      font-weight: bold;
      border: 1px solid #dee2e6;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2px;
      padding: 20px 4px 2px 4px;
      width: 200px;
      height: 100%;

      .upload-content-wrap {
        position: relative;
        vertical-align: top;
        padding: 2px;
        margin: 2px;

        .close {
          position: absolute;
          display: inline-block;
          width: 25px;
          height: 25px;
          padding-left: 2px;
          line-height: 0.7;
          color: floralwhite;
          text-shadow: 1px 1px #282c34;
          border-radius: 50%;
          background-color: rgb(99, 99, 99, 0.5);
          ;
          font-size: 28px;
          top: 2px;
          right: 2px;
          z-index: 100;
        }
      }

      .file-upload-container {
        position: relative;
        text-align: center;
        color: gray;

        .file-upload-hint {
          position: absolute;
          top: -20px;
          left: 40px;
          color: black;
        }
      }

    }

  }

}

.skillReviewTitle{
  
  font-size: 32px;
  margin-top: 50px;
  margin-bottom: 60px;
  font-weight: 800;
}