:root {
  --app-color-app-bg: rgb(255, 248, 241);  
  --bg-orange: rgb(255, 204, 102);
  --button-orange: rgb(255, 102, 51);
  --button-pink: rgb(255, 102, 153);
  --bg-light-yellow: rgb(255, 235, 194);
  --table-border-green: rgb(0, 204, 153);
  --table-bg-green: rgb(153, 235, 214);
  --table-bg-lightgreen: rgb(187, 245, 189);
  --modal-title-pink: rgb(255, 153, 204);
  --table-border-pink: rgb(255, 153, 204);
  --table-bg-pink: rgb(255, 194, 194);
  --table-border-orange: rgb(255, 102, 51);
  --table-bg-orange: rgb(255, 204, 102);
  --table-bg-white: rgb(255, 255, 255);
  --table-bg-lightyellow: rgb(245, 245, 245);
  --skill-cat-bg-yellow: rgb(255, 255, 0);
  --skill-cat-bg-blue: rgb(100, 189, 229);
  --skill-cat-bg-red: rgb(245, 184, 212);
  --skill-cat-bg-green: rgb(139, 191, 75);
  --skill-cat-bg-lightyellow: rgb(255, 248, 213);
  --skill-cat-bg-lightblue: rgb(226, 245, 255);
  --skill-cat-bg-pink: rgb(255, 231, 240);
  --skill-cat-bg-lightgreen: rgb(235, 249, 235);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Login-div {
  padding: 20px;
  margin: 10% auto;
  width: 40%;
  height: 400px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.navbar-div {
  background: var(--bg-orange);
}

/* unvisited link */
.navlink:link {
  color: black;
}

/* visited link */
.navlink:visited {
  color: black;
}

/* mouse over link */
.navlink:hover {
  color: white;
}

/* selected link */
.navlink:active {
  color: orangered;
}

.navlink-div {
  background: var(--bg-orange);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 3px;
  padding: 6px 20px 6px 20px;
}

.logout-btn {
  background: var(--button-orange);
  color: white;
  font-weight: bold;
  border-width: 1px;
  letter-spacing: 3px;
  padding: 4px 20px 4px 20px;
}

.submit-btn {
  background: var(--button-pink);
  color: white;
  font-weight: bold;
  border-width: 1px;
  letter-spacing: 3px;
  padding: 4px 20px 4px 20px;
}

.modal-btn {
  min-width: 140px;
  background: var(--bg-orange);
  color: black;
  font-weight: bold;
  border-width: 1px;
  letter-spacing: 3px;
  padding: 4px 20px 4px 20px;
}

.modal-btn:disabled,
.modal-btn[disabled]{
  border: 1px solid #999999;
  background-color: rgb(255, 204, 102, 0.7);;
  color: #666666;
}

.video-upload-btn {
  color: black;
  background-color: white;
  font-weight: bold;
  border-width: 3px;
  border-color: var(--button-orange);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 20px 4px 2px 4px;
}


.about-header-div {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}

.about-div {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}

.upload-modal-header {
  background: var(--bg-orange);
}

.upload-modal-row {
  background: var(--bg-light-yellow);
}

.modal-check-input:checked, .modal-check-input:not(:checked){
  box-shadow: 0 0 0 0.1rem var(--button-orange) !important;
  outline: 0;
  border: 0;
}

.modal-check-input:checked {
  background-color: var(--button-orange);
  box-shadow: 0 0 0 0.1rem var(--button-orange) !important;
  outline: 0;
  border: 0;
}

.form-check-input:focus, .label::after, label.form-check-label:focus, .form-check-input::after, .form-check-input:not(:disabled):not(.disabled):active:focus {
  color: black;
  outline: 0;
  border: 0;
  box-shadow: 0 0 0 0.1rem var(--button-orange) !important;
  background-color: var(--button-orange);
}

.form-check-input:checked, .form-check-input:not(:checked) {
  box-shadow: 0 0 0 0.1rem var(--button-orange) !important;
  outline: 0;
  border: 0;
}

.modal-text-input {
  border-color: var(--button-orange);
  border-width: 2px;
}

.modal-field-name {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 3px;
}

.modal-field-hint {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
}

.modal-field-details {
  background: var(--bg-light-yellow);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 4px 4px 4px 4px;
}

.modal-field-error {
  color: #C70039 ;
  font-size: 16px;
  padding-top: 6px;
  letter-spacing: 1px;
}

.modal-field-warning {
  color: #282c34;
  font-size: 16px;
  padding-top: 6px;
  letter-spacing: 1px;
}

.modal-options {
  font-size: 14px;
  font-weight: bold;
  font-weight: bold;
}

.modal-custom-checkbox:focus, .label::after, label.form-check-label:focus, .modal-custom-checkbox::after, .modal-custom-checkbox:not(:disabled):not(.disabled):active:focus {
  border-color: #D7B1D7;
  background-color: #D7B1D7;
}

.sharing-modal-header {
  background: var(--button-orange);
}

.sharing-modal-field-name {
  color: var(--button-orange);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 3px;
}

.sharing-modal-field-hint {
  color: var(--button-orange);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
}

.sharing-modal-field-details {
  background: var(--bg-light-yellow);
  color: var(--button-orange);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 4px 4px 4px 4px;
}

.sharing-modal-text-input {
  border-color: var(--button-orange);
  background: var(--button-orange);
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
  border-width: 2px;
}

.Main-body {
  height: 100vh;
}

.Main-div {
  text-align: center;
}

.table-upload-history {
  text-align: center;
  border: 2px solid var(--table-border-green);
}

.table-upload-history>thead {
  background-color: var(--table-bg-green);
  height: 40px;
}

.table-upload-history>tbody>tr {
  height: 40px;
}

.review-modal-header {
  background: var(--modal-title-pink);
}

.review-modal-row {
  background: var(--table-bg-pink);
}

.review-modal-btn {
  min-width: 140px;
  background: var(--modal-title-pink);
  color: black;
  font-weight: bold;
  border-width: 1px;
  letter-spacing: 3px;
  padding: 4px 20px 4px 20px;
}

.review-modal-btn:disabled,
.review-modal-btn[disabled]{
  border: 1px solid #999999;
  background-color: rgb(255, 194, 194, 0.7);;
  color: #666666;
}

.table-content-review {
  text-align: center;
  border: 2px solid var(--table-border-pink);
}

.table-content-review>thead {
  background-color: var(--table-bg-pink);
  height: 40px;
}

.table-content-review>tbody>tr {
  height: 40px;
}

.table-content-logs {
  text-align: center;
  border: 2px solid var(--table-border-green);
}

.table-content-logs>thead {
  background-color: var(--table-bg-lightgreen);
  height: 40px;
}

.table-content-logs>tbody>tr {
  height: 40px;
}

.skill-log-view-bar {
  background-color: var(--table-border-green);
  height: 10px;
  margin: 6px 0px 0px;
}

.table-content-logs-category {
  text-align: center;
  border: 2px solid white;
}

.table-content-logs-category>thead {
  background-color: var(--button-orange);
  color: white;
  height: 40px;
}

.table-content-logs-category>tbody>tr {
  height: 40px;
}

.table-data-management {
  text-align: center;
  border: 2px solid var(--table-border-orange);
}

.table-data-management>thead {
  background-color: var(--table-bg-orange);
  height: 40px;
}

.table-data-management>tbody>tr {
  height: 40px;
}

.upload-image-wrap {
  position: relative;
  vertical-align: top;
}

.upload-image-wrap .close {
  position: absolute;
  display: inline-block;
  width: 25px;
  height: 25px;
  padding-left: 1px;
  line-height: 0.7;
  color: floralwhite;
  text-shadow: 1px 1px #282c34;
  border-radius: 50%;
  background-color: rgb(99, 99, 99, 0.5);;
  font-size: 28px;
  top: 2px;
  right: 2px;
  z-index: 100;
}

.video-link-wrap {
  position: relative;
  vertical-align: top;
}

.video-link-wrap .play {
  position: absolute;
  display: inline-block;
  width: 48px;
  height: 48px;
  padding-left: 1px;
  line-height: 0.7;
  color: floralwhite;
  text-shadow: 2px 2px #282c34;
  border-radius: 50%;
  background-color: rgb(99, 99, 99, 0.5);
  font-size: 24px;
  top: 15px;
  right: 40px;
  z-index: 100;
}
