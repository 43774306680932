.rewriteModalStyle {
  .ant-modal {
    .ant-modal-content {
      padding: 0 !important;
      border-radius: 10px;

      .ant-modal-header {
        margin: 0;
        border-radius: 20px 20px 0 0;

        .ant-modal-title {
          text-align: center !important;
          height: 40px;
          background: #ffcc66 !important;
          line-height: 40px;
          border-radius: 10px 10px 0 0;
        }
      }

      .ant-modal-body {
        background: #ffebc2;
        padding: 40px;

        .modalBtnBox {
          position: relative;

          .saveBtn {
            position: absolute;
            left: 180px;
            width: 120px;
            height: 40px;
            border-radius: 20px;
            background: #ffcc66;
            font-size: 16px;
            font-weight: 700;
            &:hover{
             border: 1px solid #f39800;
             color: black;
            }
          }

          .cancelBtn {
            position: absolute;
            left: 420px;
            width: 120px;
            height: 40px;
            border-radius: 20px;
            background: #ffcc66;
            font-size: 16px;
            font-weight: 700;
            &:hover{
              border: 1px solid #f39800;
              color: black;
             }
          }
        }
      
        .ant-select-disabled{
          .ant-select-selector{
            background: #ffebc2;
            border: 0;
            color: black;
            cursor: default;
          }
          .ant-select-arrow{
            display: none;
          }
        }
        .ant-input-disabled{
          background: #ffebc2;
          border: none;
          color: black;
          font-size: 16px;
          cursor: default;
        }
      }
    }
  }
}
.userMangeTitle{
  font-size: 32px;
  margin-top: 50px;
  margin-bottom: 20px;
  font-weight: 800;
}